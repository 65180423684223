import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

interface TABCONFIG {
  tabs: Array<any>;
}

export default function TabDynamic(props: TABCONFIG) {
  const { tabs } = props;
  const [value, setValue] = React.useState(tabs[0]?.value);
  const [isPending, setTransition] = React.useTransition();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTransition(() => {
      setValue(newValue);
    })
  };

  return (
    <Box sx={{ width: "100%", typography: "body1", position: "relative" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {tabs?.map((list: any) => {
              return (
                <Tab label={list.label} value={list.value} key={list.id} />
              );
            })}
          </TabList>
        </Box>
        {tabs?.map((list: any) => {
          const TagName = list.component;
          return (
            <TabPanel value={list.value} key={list.id} sx={{ padding: 0 }}>
              <TagName />
            </TabPanel>
          );
        })}
      </TabContext>
    </Box>
  );
}
