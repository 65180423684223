export const baseUrl = process.env.REACT_APP_BASE_URL;
export const APIMETHOD = {
  user: {
    LOGIN: `${baseUrl}/api/kanban/login/`,
    REGISTER_USER: `user/register`,
    ADD_USER: `${baseUrl}/api/kanban/reminder-user/`,
    GET_ALL: `${baseUrl}/api/kanban/reminder-user/`,
  },
  clients: {
    ADD_CLIENT: `${baseUrl}/api/kanban/client/`,
    EDIT_CLIENT: `${baseUrl}/api/kanban/client/`,
    GET_CLIENT: `${baseUrl}/api/kanban/client/`,
    GET_STAGE: `${baseUrl}/api/kanban/stages/`,
    MOVE_LOST: `${baseUrl}/api/kanban/move-to-lost/`
  },
  reminder: {
    ADD_REMINDER: `${baseUrl}/api/kanban/reminder/`,
    GET_ALL: `${baseUrl}/api/kanban/reminder/`,
    REMINDER_USER: `${baseUrl}/api/kanban/reminder-user/`,
    SEND_REMINDER: `${baseUrl}/api/kanban/reminder/send/`,
  },
  address: {
    ADDRESS_API: `${baseUrl}/api/kanban/site/`
  },
  stages: {
    ADD_STAGE: `${baseUrl}/api/kanban/stages/`,
    GET_STAGE_BY_ID: `${baseUrl}/api/kanban/stages/:id`,
  },
  SEND_EMAIL: `${baseUrl}/api/kanban/send-email/`,
  SEND_EMAIL_BULK: `${baseUrl}/api/kanban/send-email-bulk/`,
  SEND_EMAIL_TASKLIST: `${baseUrl}/api/kanban/send-email-tasklist/`,
  kv: {
    CREATE: `${baseUrl}/api/kanban/key-value/`,
  },
  DOWNLOAD_SALES_REPORT: `${baseUrl}/api/kanban/download-sales-report/`,
  UPDATE_SALES_REPORT: `${baseUrl}/api/kanban/update-sales-report/`,
  UPDATE_SALES_REPORT_DROP: `${baseUrl}/api/kanban/update-sales-report-drop/`,
};

export const menuItemsIAF = [
  { value: 1, label: "1. Agriculture, fishing" },
  { value: 2, label: "2. Mining and Quarrying" },
  { value: 3, label: "3. Food products, beverages and tobacco" },
  { value: 4, label: "4. Textiles and textile products" },
  { value: 5, label: "5. Leather and Leather products" },
  { value: 6, label: "6. Wood and wood products" },
  { value: 7, label: "7. Pulp, Paper and paper products" },
  { value: 8, label: "8. Publishing companies" },
  { value: 9, label: "9. Printing companies" },
  { value: 10, label: "10. Manuf. of coke and refined petroleum products" },
  { value: 11, label: "11. Nuclear fuel" },
  { value: 12, label: "12. Chemicals, chemical products and fibers" },
  { value: 13, label: "13. Pharmaceuticals" },
  { value: 14, label: "14. Rubber and plastic products" },
  { value: 15, label: "15. Non-metallic mineral products" },
  { value: 16, label: "16. Concrete, cement, lime, plaster, etc." },
  { value: 17, label: "17. Basic metals and fabricated metal products" },
  { value: 18, label: "18. Machinery and equipment" },
  { value: 19, label: "19. Electrical and optical equipment" },
  { value: 20, label: "20. Shipbuilding" },
  { value: 21, label: "21. Aerospace" },
  { value: 22, label: "22. Other transport equipment" },
  { value: 23, label: "23. Manufacturing not elsewhere classified" },
  { value: 24, label: "24. Recycling" },
  { value: 25, label: "25. Electricity Supply" },
  { value: 26, label: "26. Gas Supply" },
  { value: 27, label: "27. Water Supply" },
  { value: 28, label: "28. Construction" },
  { value: 29, label: "29. Wholesale and retail trade: Repair of motor vehicles, motorcycles and personal and household goods" },
  { value: 30, label: "30. Hotels and restaurants" },
  { value: 31, label: "31. Transport, storage and communication" },
  { value: 32, label: "32. Financial intermediation; real estate; renting" },
  { value: 33, label: "33. Information Technology" },
  { value: 34, label: "34. Engineering services" },
  { value: 35, label: "35. Other Services" },
  { value: 36, label: "36. Public administration" },
  { value: 37, label: "37. Education" },
  { value: 38, label: "38. Health and social work" },
  { value: 39, label: "39. Other social services" }
];

export const menuItemsLikelihoodIndicator = [
  { value: 1, label: "Red: hot opportunity", color: "red" },
  { value: 2, label: "Amber: warm opportunity", color: "orange" },
  { value: 3, label: "Blue: cold opportunity", color: "blue" },
  { value: 4, label: "Black: unknown opportunity", color: "black" },
  { value: 5, label: "No color: Haven't tried reaching out", color: "transparent" },
]

export const clientStatus = [
  { value: 1, label: "New client", color: "#90EE90" },
  { value: 2, label: "Contacted, Waiting for more information", color: "#FFD580" },
  { value: 3, label: "Contacted, but no response", color: "#FFB6B6" }
]