import { createAuthHeader } from './../../../shared/utils';
import { axiosInstance } from './../../../core/useAxios';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import Cookies from "js-cookie";
import { db } from "../../../firebase";
import { APIMETHOD } from '../../../core/constant';
export const Cardboard = {
    columns: [
      {
        stageId: 1,
        stageName: 'Ready to Do',
        cards: [
          {
            id: 1,
            clientname: 'Anup Kumar Bhagat',
            stage: 'To Do'
          },
          {
            id: 2,
            clientname: 'Sumit Kumar Bhagat',
            stage: 'To Do'
          },
        ],
        stageOrder: 1,
        stageColor: '#ccc'
      },
      {
        stageId: 2,
        stageName: 'In Progress',
        cards: [
          {
            id: 3,
            clientname: 'Ashish Kumar',
            stage: 'In - Progress'
          },
        ],
        stageOrder: 2,
        stageColor: 'red'
      },
      {
        stageId: 3,
        stageName: 'Stage 3',
        cards: [
          {
            id: 4,
            clientname: 'Ashish Kumar',
            stage: 'Stage 3'
          },
        ],
        stageOrder: 3,
        stageColor: 'green'
      },
      {
        stageId: 4,
        stageName: 'Stage 4',
        cards: [],
        stageOrder: 4,
        stageColor: 'blue'
      }
    ]
  }

  export const stageList = (data: any = Cardboard.columns) => {
    return data?.map((x: any) => {
      return {
        stagename: x.name,
        stageid: x.id
      }
    });
  }
  
//   <Board initialBoard={board} />

export const ItemTypes = {
  TASK: 'task'
}

export const getBoardTaskList = (boardList: any, index: number) => {
  return boardList.columns[index].cards || [];
}

export const defaultData = {
  defaultStageNo: 5,
  columns: [
    {
      stageId: 1,
      stageName: 'Ready to Do',
      cards: [
        {
          id: 1,
          clientname: 'client 1',
          stage: 'Ready to Do'
        },
      ],
      stageOrder: 1,
      stageColor: '#ccc'
    }
  ]
};

// With Local Storage
// export const server = () => {
//   const setData = (data: any) => {
//     localStorage.setItem('boardData', JSON.stringify(data));
//   }
//   const getData = () => {
//   const d = localStorage.getItem('boardData');
//     if(d) {
//       return JSON.parse(d);
//     }
//     return defaultData;
//   }
//   return {
//     setData,
//     getData
//   }
// }

const createKV = async(data: any) => {
    const res: any = await axiosInstance.post(APIMETHOD.kv.CREATE, data, {headers: createAuthHeader()});
    return res;
}

const editKV = async(data: any, kvId: number) => {
  const res: any = await axiosInstance.patch(APIMETHOD.kv.CREATE + kvId + '/', data, {headers: createAuthHeader()});
  return res;
}

export const server = () => {
  // const washingtonRef = doc(db, "kanbanjson", 'data');
  const setData = async (datas: any) => {
    const data: any = {
      "key": "board_data",
      "value": {
          ...datas
      }
  }
  if(datas?.boardkvId) {
      await editKV(data, datas?.boardkvId);
  } else {
      await createKV(data);
  }
    //  localStorage.setItem('boardData', JSON.stringify(datas));
    // await updateDoc(washingtonRef, datas);
  }

  // Get Data Using FireBase
  // const getData = async() => {
  //   const fd = await getDoc(washingtonRef);
  //   const d = localStorage.getItem('boardData');
  //   const data = fd.data()
  //   if(fd.exists() && JSON.stringify(data) !== '{}') {
  //     return fd.data();
  //   } else if(d && !fd.exists()) {
  //     return JSON.parse(d);
  //   }
  //   return defaultData;
  // }


  const setUser = (userInfo: any) => {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  }
  const getUser = () => {
    const u: any = localStorage.getItem('userInfo');
    return JSON.parse(u);
  }
  const getToken = (): string => {
    return Cookies.get('token') || '';
  } ;
  return {
    setData,
    setUser,
    getUser,
    getToken
  }
}
