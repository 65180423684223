
import { Alert } from '@mui/material';
import { boardContext } from './../App';
// import { useAppStateContext } from './../state/provider/index';
import { useEffect } from 'react';
import axios, { AxiosRequestConfig, CancelTokenSource, AxiosResponse, AxiosError } from "axios";
import React from 'react';
import { baseUrl } from './constant';
// import LoadingService from '../shared/loader';
// import { _LoaderService } from '../state/config/actions';

const axiosConfig: AxiosRequestConfig = {
    headers: {
        'Content-Type': 'application/json',
        // Pragma: 'no-cache'
    },
    // responseType: 'json',
    // withCredentials: true,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN'
};

export const axiosInstance = axios.create(axiosConfig);

export interface HttpRequestConfig extends AxiosRequestConfig {
    showLoader?: boolean,
    loadingMessage?: string
}

const DEFAULT_REQUEST_CONFIG: HttpRequestConfig = {
    showLoader: true,
    loadingMessage: 'Loading...'
}

export const useAxios = (requestConfig?: HttpRequestConfig) => {
    let source: CancelTokenSource;
    // const { dispatch } = useAppStateContext();
    const { config, setIsLoader } = React.useContext(boardContext);
    const token: any = config?.getToken();

    const [error, setError] = React.useState<any>(null);
    const [loading, setLoading] = React.useState<boolean>(false);

    useEffect((): any => {
        return () => source?.cancel
    }, []);

    const get = async (overrideRequestConfig?: HttpRequestConfig) => {
        return await invokeCall({...overrideRequestConfig, method: 'GET'})
    };

    const post = async (overrideRequestConfig?: HttpRequestConfig) => {
        return await invokeCall({...overrideRequestConfig, method: 'POST'})
    };

    const put = async (overrideRequestConfig?: HttpRequestConfig) => {
        return await invokeCall({...overrideRequestConfig, method: 'PATCH'})
    };

    const del = async(overrideRequestConfig?: HttpRequestConfig) => {
        return await invokeCall({...overrideRequestConfig, method: 'DELETE'})
    };

    const invokeCall = async (overrideRequestConfig: HttpRequestConfig) => {
        setError(null);
        const config = {...DEFAULT_REQUEST_CONFIG, ...requestConfig, ...overrideRequestConfig}
        source = axios.CancelToken.source();

        if(config.showLoader) {
            setLoading(true);
            setIsLoader(true);
            // dispatch(_LoaderService(config.showLoader))
            // spinnerService.Show(config.loadingMessage);
        }

        try {
            const headers: any = { ...config?.headers }
            if(token) {
                headers['Authorization'] = `Token ${token}`;
            }
            const res: AxiosResponse<any> = await axiosInstance({
                ...config,
                headers,
                cancelToken: source.token
            })
            return res.data;
            
        } catch(err: any) {
            const _err: any = handleApiError(err);
            // alert(_err.message);
            setError(_err);
        } finally {
            if(config.showLoader) {
                setLoading(false);
                setIsLoader(false);
                //dispatch(_LoaderService(config.showLoader))
                // spinnerService.Hide()
            }
        }
    }

    const handleApiError = (err: AxiosError<any>) => {
        return {
            status: err?.response?.data?.status ? err?.response?.data?.status : err?.response?.status,
            statusText: err.response ? err.response?.statusText : null,
            message: (err?.response?.data?.order && err.response.data.order[0]) || err?.message || err?.response?.data?.err || 'Server encounter an error while processing an request',
            stack: err?.stack,
            responseData: err.response ? err.response.data : null
        }
    }

    return {
        error,
        loading,
        get,
        post,
        put,
        del
    }
};