import { ReminderAction, ReminderActionTypes } from "./reminderActionTypes";

// Define state interface
export interface ReminderState {
  loading: boolean;
  reminders: any[];
  error: string;
}

// Define initial state
export const initialState: ReminderState = {
  loading: false,
  reminders: [],
  error: "",
};

// Define reducer function
export const ReminderReducer = (
  state: ReminderState,
  action: ReminderAction
): ReminderState => {
  switch (action.type) {
    case ReminderActionTypes.FETCH_REMINDERS_REQUEST:
    case ReminderActionTypes.POST_REMINDER_REQUEST:
    case ReminderActionTypes.PATCH_REMINDER_REQUEST:
    case ReminderActionTypes.DELETE_REMINDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ReminderActionTypes.FETCH_REMINDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        reminders: action.payload,
        error: "",
      };
    case ReminderActionTypes.POST_REMINDER_SUCCESS:
      return {
        ...state,
        loading: false,
        reminders: [...state.reminders, action.payload],
        error: "",
      };
    case ReminderActionTypes.PATCH_REMINDER_SUCCESS:
      const data = state?.reminders?.filter((x: any) => x.id !== action.payload.id) || state;
      return {
        ...state,
        loading: false,
        reminders: [...data, action.payload],
        error: "",
      };
    case ReminderActionTypes.DELETE_REMINDER_SUCCESS:
      const data1 = state?.reminders?.filter((x: any) => x.id !== action.payload.id) || state;
      return {
        ...state,
        loading: false,
        reminders: [...data1],
        error: "",
      };
    case ReminderActionTypes.FETCH_REMINDERS_FAILURE:
    case ReminderActionTypes.POST_REMINDER_FAILURE:
    case ReminderActionTypes.PATCH_REMINDER_FAILURE:
    case ReminderActionTypes.DELETE_REMINDER_FAILURE:
      return {
        ...state,
        loading: false,
        reminders: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
