import { server } from "../components/client-register/kanban-board/data";

const getToken = () => {
    return server().getToken();
}
export const createAuthHeader = (): { Authorization: string } => {
    const token: any = getToken();
    return {
        Authorization: `Token ${token}`
    };
};

interface IBoardData {
    defaultStageNo: number,
    from_to_email: string,
    columns: Array<any>
}
export const defaultBoardData: IBoardData = {
    defaultStageNo: 5,
    from_to_email: '',
    columns: []
  };